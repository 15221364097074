import React, { useState } from "react";

const Navodila = () => {
  return (
    <div>
      <>
      <iframe className="navodila" width="100%" src="https://ure.termo-plus.eu/TS_Navodila_za_uporabo_spletne_aplikacije_Termo_Shop_evidenca_ur.pdf" />
    </>
    </div>
  );
};
export default Navodila;
