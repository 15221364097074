import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Button from "@mui/material/Button";
import {
  deleteEntry,
  fetchEntries,
  saveNewEntry,
  updateEntry,
} from "../../store/redux/slices/entriesSlice";
import GlobalContext from "../../context/GlobalContext";

const NotesForm = (props) => {
  const user = useSelector((state) => state.msalInstance.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const notesInputRef = useRef();
  const [toggleButton, setToggleButton] = useState(false);
  const { selectedUser } = useContext(GlobalContext);

  // let username = location.state ? location.state.name : user.username;
  let username = selectedUser ? selectedUser : user.username;

  async function handleSubmit(e) {
    e.preventDefault();
    var selectedDate = props.daySelected.valueOf();
    var selectedEvent = props.selectedEvent;
    let notesInputValue = notesInputRef.current.value;

    const day = {
      date: selectedDate,
      username: username,
      entry_id: selectedEvent.entry_id,
      day: selectedDate.valueOf(),
      notes: notesInputValue,
      updated: true,
    };

    dispatch(updateEntry(day));
    dispatch(fetchEntries({ username }));
    triggerRefresh();
  }

  const handleButtonToggle = () => {
    setToggleButton((prevState) => !prevState);
  };

  const triggerRefresh = () => {
    let notesInputValue = notesInputRef.current.value;

    props.triggerRefresh(notesInputValue);
  };

  return (
    <>
      <div>
        <div className="mb-2">
          <div className={`${toggleButton ? "hidden" : "d-block "} mb-2`}>
            <Button
              className="text-normal toggleButton"
              onClick={handleButtonToggle}
            >
              {" "}
              <TextSnippetIcon /> <p className="ml-2">Dodaj opombo</p>
            </Button>
          </div>
          <div className={toggleButton ? "d-block" : "hidden"}>
            <TextField
              className="w-full"
              label="Vpiši opombe..."
              placeholder="Vpiši opombe..."
              multiline
              maxRows={4}
              inputRef={notesInputRef}
              variant="filled"
            ></TextField>
            <div className="mt-3">
              <Button
                variant="contained"
                onClick={handleSubmit}
                className="btn w-full primary-btn bg-red-500 p-5 rounded text-white"
              >
                DODAJ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotesForm;
