import { Fragment } from "react";
import Header from "./Header/Header";

const Layout = (props) => {
  return (
    <Fragment>
      <div className="wrapper">
        <main className="main">{props.children}</main>
      </div>
    </Fragment>
  );
};
export default Layout;
