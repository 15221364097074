import Header from "../Header/Header";
import { Container } from "@mui/system";

const Report = () => {
  return (
    <div>
      <Header />
      <Container className="mt-5 pt-67">
    </Container>
    </div>
  );
};

export default Report;