import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    setMsalInstance,
    setAuthStatus,
    setUser,
  } from "../../store/redux/slices/msalInstanceSlice";

function AuthCallback() {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Get the navigation function

  useEffect((e) => {
    instance.handleRedirectPromise().then((authResponse) => {
      if (authResponse) {
        const accounts =  instance.getAllAccounts();
        if (accounts.length > 0) {
          // User is authenticated
          dispatch(setAuthStatus(true)); // Set the authentication status in your Redux store
          dispatch(setUser(accounts[0])); // Set the user info in your Redux store
          //navigate('/');
        }
      }
    });
  }, [ instance, dispatch]);
  return(<div>Sucess login</div>)
  
}

export default AuthCallback;
