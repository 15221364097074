import axios from "axios";

const today = new Date();

export const addEntry = async (startTime, endTime, userId, selectedDate) => {
  const day = {
    date: selectedDate,
    userId: userId,
    startTime: startTime,
    endTime: endTime,
    day: selectedDate.valueOf(),
    entry_id: Date.now(),
  };

  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/entries",
    day
  );
};

export const getWorkingDays = async (year, month) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);

  const workingDays = [];
  
  for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
    const dayOfWeek = date.getDay();

    // Check if it's not Saturday (6) or Sunday (0)
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      workingDays.push(new Date(date));
    }
  }

  let workingDayCount = 0;
  workingDayCount = workingDays.length;

  return workingDayCount;
};

export const getDateValue = () => {
  const numericDateValue = 1700611200000;
  const dateFromValue = new Date(numericDateValue);
  const dateStringFromValue = dateFromValue.toISOString();
}
