import axios from "axios"

export const getRemainingVacation = async (username) => { 
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/vacation/${username}`).then((docs) => {
        if(docs) {
            return docs.data;
        } else {
            return null;
        }
    });

    return response;
}