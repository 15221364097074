import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import {
  deleteEntry,
  fetchEntries,
  saveNewEntry,
  updateEntry,
} from "../../store/redux/slices/entriesSlice";
import GlobalContext from "../../context/GlobalContext";
import utc from "dayjs/plugin/utc";
import { TextField, TextareaAutosize } from "@mui/material";
dayjs.extend(utc);

const WorkingTimeForm = (props) => {
  const location = useLocation();
  const user = useSelector((state) => state.msalInstance.user);
  const { selectedUser } = useContext(GlobalContext);

  // let username = location.state ? location.state.name : user.username;
  let username = selectedUser ? selectedUser : user.username;

  const { setShowEventModal } = useContext(GlobalContext);

  const today = new Date();

  const { monthIndex, setMonthIndex } = useContext(GlobalContext);

  const dispatch = useDispatch();

  const [startTime, setStartTime] = useState(
    dayjs(props.daySelected).utc().set("hours", 7)
  );
  const [endTime, setEndTime] = useState(
    dayjs(props.daySelected).utc().set("hours", 15)
  );
  const [differenceInMinutes, setdifferenceInMinutes] = useState(8);
  const [overtimeAllowed, setOvertimeAllowed] = useState(true);

  const notesInputRef = useRef();

  const handleStartTimeChange = (time) => {
    const date = new Date(time);
    const targetTimeUTC = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    );
    targetTimeUTC.setUTCHours(
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );

    setStartTime(targetTimeUTC);
    //checkDiffTime();
  };

  const handleEndTimeChange = (time) => {
    const date = new Date(time);
    const targetTimeUTC = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    );
    targetTimeUTC.setUTCHours(
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );

    setEndTime(targetTimeUTC);
    //checkDiffTime();
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const startDate = new Date(dayjs().year(), monthIndex - 1, 1);
    const endDate = new Date(dayjs().year(), monthIndex + 1, 31);
    var selectedDate = props.daySelected.valueOf();
    var selectedEvent = props.selectedEvent;
    let notesInputValue = notesInputRef.current.value;

    const targetTime = new Date(selectedDate);
    const targetTimeUTC = new Date(
      Date.UTC(
        targetTime.getUTCFullYear(),
        targetTime.getUTCMonth(),
        targetTime.getUTCDate(),
        targetTime.getUTCHours(),
        targetTime.getUTCMinutes(),
        targetTime.getUTCSeconds()
      )
    );

    targetTimeUTC.setUTCHours(10, 0, 0, 0);

    // Add random minutes (up to 15 minutes)
    const randomMinutes = Math.floor(Math.random() * 16); // 0 to 15 minutes
    targetTimeUTC.setUTCMinutes(targetTimeUTC.getUTCMinutes() + randomMinutes);

    const lunchTimeEnd = new Date(targetTimeUTC);
    lunchTimeEnd.setUTCMinutes(targetTimeUTC.getUTCMinutes() + 30);

    if (props.selectedEvent) {
      const day = {
        date: selectedDate,
        username: username,
        startTime: startTime,
        endTime: endTime,
        lunchTime: targetTimeUTC,
        lunchTimeEnd: lunchTimeEnd,
        day: selectedDate.valueOf(),
        entry_id: selectedEvent.entry_id,
      };

      dispatch(updateEntry(day));
      // dispatch(fetchEntries({ username, startDate, endDate }));

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
        username: user.username,
        date: today,
        action: "updated entry",
        day: selectedDate.valueOf(),
        system: false,
      });
    } else {
      const day = {
        date: selectedDate,
        username: username,
        startTime: startTime,
        endTime: endTime,
        lunchTime: targetTimeUTC,
        lunchTimeEnd: lunchTimeEnd,
        day: selectedDate.valueOf(),
        entry_id: Date.now(),
        notes: notesInputValue,
      };

      dispatch(saveNewEntry(day));
      // dispatch(fetchEntries({ username, startDate, endDate }));

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
        username: user.username,
        date: today,
        action: "added entry",
        day: selectedDate.valueOf(),
        system: false,
      });
    }

    dispatch(fetchEntries({ username, startDate, endDate }));

    setShowEventModal(false);
    document.body.style.overflow = "unset";
  }

  const checkDiffTime = () => {
    const start = dayjs(startTime);
    const end = dayjs(endTime);

    if (dayjs.isDayjs(start) && dayjs.isDayjs(end)) {
      const differenceInMinutes = end.diff(start, "minute");
      const hours = Math.floor(differenceInMinutes / 60);
      const minutes = differenceInMinutes % 60;

      setdifferenceInMinutes(differenceInMinutes);
    } else {
      console.log("startTime or endTime is not a valid Day.js object");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/user")) {
      // username = location.state.name;
      username = selectedUser;
    } else {
      username = user.username;
    }
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="mb-4">
          <TimePicker
            ampm={false}
            label="Prihod"
            defaultValue={dayjs(props.daySelected).set("hours", 7)}
            className="w-full"
            onChange={handleStartTimeChange}
          />
        </div>
        <div className="mb-2">
          <TimePicker
            ampm={false}
            label="Odhod"
            defaultValue={dayjs(props.daySelected).set("hours", 15)}
            className="w-full"
            onChange={handleEndTimeChange}
          />
        </div>
      </LocalizationProvider>
      <div>
        <TextField
          className="w-full"
          label="Vpiši opombe..."
          placeholder="Vpiši opombe..."
          multiline
          maxRows={4}
          inputRef={notesInputRef}
        ></TextField>
      </div>

      <footer className="flex justify-end mt-2 flex-col">
        {differenceInMinutes > 480 && !overtimeAllowed && (
          <Alert severity="error" className="mb-3 text-left">
            Za odobritev nadur kontaktiraj svojega vodjo!
          </Alert>
        )}

        <Button
          variant="contained"
          onClick={handleSubmit}
          className="btn w-full primary-btn bg-red-500 p-5 rounded text-white"
          disabled={
            differenceInMinutes > 480 && !overtimeAllowed ? true : false
          }
        >
          DODAJ
        </Button>
      </footer>
    </>
  );
};

export default WorkingTimeForm;
