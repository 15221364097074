import { configureStore } from "@reduxjs/toolkit";
import entriesReducer from "./slices/entriesSlice";
import usersReducer from "./slices/usersSlice";
import msalInstanceReducer from "./slices/msalInstanceSlice";

const store = configureStore({
  reducer: { entries: entriesReducer, users: usersReducer, msalInstance: msalInstanceReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
