import { DataGrid, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Header from "../Header/Header";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  selectAllUsers,
  updateUsers,
} from "../../store/redux/slices/usersSlice";
import { getMonth } from "../../util";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckIcon from "@mui/icons-material/Check";
import Spinner from "../Spinner/Spinner";
import "./Admin.css";

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers);
  const [usersList, setUsersList] = useState(users);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [allHoursConfirmed, setAllHoursConfirmed] = useState(false);
  const [hoursConfirmed, setHoursConfirmed] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [exportOption, setExportOption] = useState(true);

  const [usersForExport, setUsersForExport] = useState([]);
  const [usersToApprove, setUsersToApprove] = useState([]);
  const [tempUsersList, setTempUsersList] = useState([]);
  const [isHidden, setIsHidden] = useState(false);

  const user = useSelector((state) => state.msalInstance.user);
  const loggedUser = useSelector((state) => state.msalInstance.user);

  const months = [
    "Januar",
    "Februar",
    "Marec",
    "April",
    "Maj",
    "Junij",
    "Julij",
    "Avgust",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  const d = new Date();
  let month = d.getMonth();
  let monthName = months.indexOf(months[month]);

  var thisYear = d.getFullYear();
  const [selectedYear, setSelectedYear] = useState(thisYear);
  const [selectedMonth, setSelectedMonth] = useState(monthName);
  const years = [];

  const [currenMonth, setCurrentMonth] = useState(
    getMonth(selectedMonth, selectedYear)
  );

  const minOffset = 0;
  const maxOffset = 60;

  const columns = [
    {
      field: "displayName",
      headerName: "Priimek in ime",
      flex: 1,
      headerClassName: "bg-gray-100",
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 2,
      headerClassName: "bg-gray-100",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "bg-gray-100",
      renderCell: (params) => (
        <div className="status">
          <span
            className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset   ${
              params.value
                ? "bg-green-50 text-green-700 ring-green-600/20"
                : "bg-red-50 text-red-700 ring-red-600/10"
            }`}
          >
            {" "}
            {params.value ? "Potrjeno" : "Nepotrjeno"}
          </span>
        </div>
      ),
    },
    {
      field: "visible",
      headerName: "Vidnost",
      flex: 1,
      headerClassName: "bg-gray-100",
      renderCell: (params) => (
        <div className="status">
          <span
            className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset   ${
              params.value
                ? "bg-green-50 text-green-700 ring-green-600/20"
                : "bg-red-50 text-red-700 ring-red-600/10"
            }`}
          >
            {" "}
            {params.value ? "Viden" : "Neviden"}
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("currentMonth") !== null) {
        setSelectedMonth(localStorage.getItem("currentMonth"));
      }
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/all`);
      if (res.status === 200 && res.data.users) {
        setTempUsersList(res.data.users);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(fetchUsers);
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("currentMonth", selectedMonth);
    localStorage.setItem("currentYear", selectedYear);

    setSelectedYear(selectedYear);
    setCurrentMonth(getMonth(selectedMonth, selectedYear));

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    updateUserStatus(users);
  }, [users]);

  useEffect(() => {
    updateUserStatus(users);
  }, [selectedYear, selectedMonth, users]);

  useEffect(() => {
    checkApprove();
  }, [selectedYear, selectedMonth]);

  const checkApprove = async () => {
    let currentMonth = parseInt(localStorage.getItem("currentMonth")) + 1;
    let currentYear = parseInt(localStorage.getItem("currentYear"));

    const approve = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/approval-logs/${currentMonth}/${currentYear}`
      )
      .then((doc) => {
        if (doc.data !== null) {
          setHoursConfirmed(true);
        } else {
          setHoursConfirmed(false);
        }
      });
  };

  const checkConfirmations = async (users) => {
    let currentMonth = parseInt(localStorage.getItem("currentMonth")) + 1;
    let currentYear = parseInt(localStorage.getItem("currentYear"));

    for (const user of users) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/approval-hours`,
          {
            username: user.username,
            month: currentMonth,
            year: currentYear,
          }
        );

        if ((res.data && !res.data.status) || res.data == null) {
          // If any user is not approved, set the variable to false and break out of the loop
          setAllHoursConfirmed(false);
          break;
        } else {
          setAllHoursConfirmed(true);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleRowClick = (params) => {
    navigate(`/user/${params.row.username}`, {
      state: { id: params.id, name: params.row.username },
    });
  };

  const handleExport = async () => {
    let currentMonth = parseInt(localStorage.getItem("currentMonth"));
    let currentYear = parseInt(localStorage.getItem("currentYear"));

    setLoadingSpinner(true);

    await axios
      .post(`${process.env.REACT_APP_API_URL}/excel`, {
        month: currentMonth,
        year: currentYear,
        currentMonth: currenMonth,
        users: usersForExport,
        isAdmin: true,
      })
      .then((body) => {
        setTimeout(() => {
          if (body.data.success == true) {
            window.location.href =
              process.env.REACT_APP_API_URL +
              "/excel/downloadZip/" +
              months[currentMonth] +
              "/" +
              selectedYear;
          }
          setLoadingSpinner(false);
        }, 5000);
      })
      .finally(() => {});
  };

  const onHandleChangeMonth = (evt) => {
    setSelectedMonth(evt.target.value);
    localStorage.setItem("currentMonth", evt.target.value);

    setCurrentMonth(getMonth(selectedMonth, selectedYear));
  };

  const onHandleChangeYear = (evt) => {
    setSelectedYear(evt.target.value);
    localStorage.setItem("currentYear", evt.target.value);
  };

  //Generate year dropdown
  for (let i = minOffset; i <= maxOffset; i++) {
    const year = thisYear - i;
    years.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const getRowProps = (params) => {
    const dataId = params.row.username; // Use the username as data-id
    return {
      "data-id": dataId,
    };
  };

  const fetchUserStatus = async (element) => {
    const monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
    const yearNumber = parseInt(localStorage.getItem("currentYear"));

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/approval-hours`,
        {
          username: element.username,
          month: monthNumber,
          year: yearNumber,
        }
      );

      return {
        id: element.id,
        displayName: element.displayName,
        email: element.email,
        status: res.data ? res.data.status : false,
        username: element.username,
        visible: element.visible !== undefined ? element.visible : true,
      };
    } catch (error) {
      console.error("Error fetching user status:", error);
      return null;
    }
  };

  const updateUserStatus = async (users) => {
    if (users.length > 1) {
      const updatedUsers = await Promise.all(users.map(fetchUserStatus));
      const filteredUsers = updatedUsers.filter((user) => user !== null);

      // First, transform the displayName for each user, then sort by last name
      const userToDisplay = filteredUsers
        .map((user) => {
          const fullName = user.displayName || "";
          const firstSpaceIndex = fullName.indexOf(" ");

          const firstName =
            firstSpaceIndex === -1
              ? fullName
              : fullName.substring(0, firstSpaceIndex);
          const lastName =
            firstSpaceIndex === -1
              ? ""
              : fullName.substring(firstSpaceIndex + 1);

          return {
            ...user,
            displayName: lastName + " " + firstName,
          };
        })
        .sort((a, b) => {
          const lastNameA = a.displayName.split(" ")[0];
          const lastNameB = b.displayName.split(" ")[0];

          return lastNameA.localeCompare(lastNameB);
        });

      // console.log(userToDisplay);
      setUsersList(userToDisplay);
    }
  };

  const approveHours = async () => {
    const monthNumber = parseInt(localStorage.getItem("currentMonth"));
    const yearNumber = parseInt(localStorage.getItem("currentYear"));
    const today = new Date();

    await axios
      .post(`${process.env.REACT_APP_API_URL}/email`, {
        month: monthNumber,
        year: yearNumber,
      })
      .then(async (doc) => {
        if (doc.data != null) {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/approval-logs`,
            {
              username: user.username,
              month: monthNumber + 1,
              year: yearNumber,
            }
          );

          checkApprove();

          const log = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
            username: user.username,
            date: today,
            month: monthNumber,
            year: yearNumber,
            action: "hours confirmed and notificiation sent",
            system: false,
          });
        }
      });
  };

  const handleSelectionChange = (selectionModel) => {
    const selectedIDs = new Set(selectionModel);
    const selectedRowData = usersList.filter((row) => selectedIDs.has(row.id));
    setSelectedRows(selectedRowData);

    if (selectedRowData.length != 0) {
      setExportOption(false);
    } else {
      setExportOption(true);
    }
    // Filter the users array to include only those whose email matches any email in selectedRowData
    const newFilteredUsers = users.filter((user) =>
      selectedRowData.some(
        (selectedRow) => selectedRow.email === user.email && !selectedRow.status
      )
    );

    setUsersToApprove((prevUsersToApprove) => [
      // ...prevUsersToApprove,
      ...newFilteredUsers.map((user) => {
        if (user.role && user.role[0]) {
          return {
            username: user.username,
            role: user.role[0],
          };
        }
      }),
    ]);

    setUsersForExport(newFilteredUsers);
  };

  const toggleHidden = () => {
    // console.log(users);
    // console.log(tempUsersList);
    if (isHidden) {
      const hiddenRows = tempUsersList.filter((user) => user.visible === true);
      updateUserStatus(hiddenRows);
    } else {
      updateUserStatus(tempUsersList);
    }
    setIsHidden(!isHidden);
    // console.log(hiddenRows);
    // setUsersList(hiddenRows);
  };

  const getRowClassName = (params) => {
    // return "hidden-row";
    // console.log(params);
    return params.row.visible === false ? "hidden-row" : "";
  };

  // Approve multiple hours

  const approveMultipleHours = async () => {
    setIsLoading(true);
    const monthNumber = parseInt(selectedMonth) + 1;
    const yearNumber = parseInt(localStorage.getItem("currentYear"));
    const today = new Date();

    for (const user of usersToApprove) {
      if (user !== undefined) {
        const username = user.username;

        let malice;
        let prevozi;
        let nadomestilo;
        let nadure;
        let usedOvertime;
        let overtimeToDisplay;

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/entries/getByMonth`,
          {
            username: user.username,
            monthNumber: parseInt(selectedMonth) + 1,
            yearNumber: yearNumber,
            role: user.role,
          }
        );

        if (response.data) {
          malice = response.data.malice;
          prevozi = response.data.prevozi;
          nadomestilo = response.data.nadomestilo;
          nadure = response.data.nadure;
          usedOvertime = response.data.usedOvertime;
        }

        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/${user.username}`
        );

        if (
          userResponse &&
          userResponse.data &&
          userResponse.data.overtimeHours
        ) {
          overtimeToDisplay = userResponse.data.overtimeHours + nadure;
        }

        const response2 = await axios
          .post(`${process.env.REACT_APP_API_URL}/approval-hours/approve`, {
            username: user.username,
            month: monthNumber,
            year: yearNumber,
            status: true,
            date: today,
          })
          .then(async (response) => {
            if (response.status == 201) {
              const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/log`,
                {
                  username: loggedUser.username,
                  employee: user.username,
                  date: today,
                  action: "hours confirmed",
                  system: false,
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });

        const status = await axios
          .post(process.env.REACT_APP_API_URL + "/approval-hours", {
            username: username,
            month: monthNumber,
            year: yearNumber,
          })
          .then(async (response) => {
            if (!response.data.overtimeHoursApproved) {
              const overtimeHours = await axios
                .get(`${process.env.REACT_APP_API_URL}/users/${user.username}`)
                .then((body) => {
                  return body.data.overtimeHours;
                });

              const newOverTimeHours = overtimeHours + nadure - usedOvertime;
              const overtimeBefore = overtimeToDisplay;

              const response2 = await axios
                .patch(`${process.env.REACT_APP_API_URL}/users`, {
                  username: user.username,
                  hoursCorrection: newOverTimeHours,
                })
                .then(async (response) => {
                  const overtimeHoursApproved = await axios
                    .post(
                      `${process.env.REACT_APP_API_URL}/approval-hours/approve`,
                      {
                        username: user.username,
                        month: monthNumber,
                        year: yearNumber,
                        status: true,
                        date: today,
                        lunches: malice,
                        transportations: prevozi,
                        overtimeHoursApproved: true,
                        compensation: nadomestilo,
                        overtimeHistory: overtimeBefore,
                        overtimeHoursUsed: usedOvertime,
                        afterOvertimeHoursUsed: newOverTimeHours,
                      }
                    )
                    .then(async (response) => {
                      const res = await axios.post(
                        `${process.env.REACT_APP_API_URL}/log`,
                        {
                          username: loggedUser.username,
                          employee: user.username,
                          date: today,
                          action: "overtime confirmed",
                          overtime_previous: overtimeBefore,
                          overtime_new: newOverTimeHours,
                          overtime_used: usedOvertime,
                          system: false,
                        }
                      );
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            updateUserStatus(users);
          });
      }
    }
    setIsLoading(false);
    setUsersToApprove([]);
  };

  return (
    <div>
      <Header />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container maxWidth="xl" className="text-right pt-[90px]">
          <div className="flex flex-wrap align-middle">
            <div
              className={`${
                !hoursConfirmed ? "block" : "hidden"
              } mr-2 md:order-1 my-auto`}
            >
              <Button
                className=""
                variant="contained"
                size="small"
                color="success"
                onClick={approveMultipleHours}
                disabled={usersToApprove.length == 0}
              >
                <CheckIcon className="mr-1" />
                <span className="hidden md:block">Potrdi</span>
              </Button>
            </div>
            <div
              className={`${
                !hoursConfirmed ? "block" : "hidden"
              } mr-2 md:order-1`}
            >
              <Button
                className="download-btn h-full"
                variant="transaprent"
                size="small"
                onClick={approveHours}
              >
                <CheckIcon className="mr-1" />
                <span className="hidden md:block">
                  Potrdi ure in obvesti računovodstvo
                </span>
              </Button>
            </div>

            <div className="md:order-3">
              <select
                className="border-gray-200"
                value={selectedMonth}
                onChange={onHandleChangeMonth}
              >
                {months.map((el, index) => (
                  <option key={el} value={index}>
                    {el}
                  </option>
                ))}
              </select>
            </div>

            <div className="ml-2 mr-2 md:order-4">
              <select
                className="border-gray-200"
                value={selectedYear}
                onChange={onHandleChangeYear}
              >
                {years}
              </select>
            </div>

            <div className="order-last md:order-5 w-full md:w-auto mt-3 md:mt-0">
              {loadingSpinner ? (
                <Spinner />
              ) : (
                <Button
                  className="download-btn w-full"
                  variant="transaprent"
                  size="small"
                  onClick={handleExport}
                  disabled={exportOption}
                >
                  <FileDownloadIcon />
                  <span className="hidden md:block">Izvozi poročilo</span>
                </Button>
              )}
            </div>

            <div className="ml-5 flex items-center justify-center flex-col md:order-6 order:5">
              {hoursConfirmed ? (
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  Potrjeno
                </span>
              ) : (
                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                  Nepotrjeno
                </span>
              )}
            </div>

            <div className="order-7 ml-auto">
              <Button
                className="download-btn w-full"
                variant="transaprent"
                size="small"
                onClick={toggleHidden}
              >
                {isHidden ? "Skrij skrite" : "Prikaži skrite"}
              </Button>
            </div>
          </div>

          <div className="mt-5" style={{ height: "700px", width: "100%" }}>
            <DataGrid
              rows={usersList}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              onRowClick={handleRowClick}
              checkboxSelection
              getRowProps={getRowProps}
              getRowClassName={getRowClassName}
              onSelectionModelChange={handleSelectionChange}
            />
          </div>
        </Container>
      )}
    </div>
  );
};

export default Admin;
