import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import GlobalContext from "../../context/GlobalContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TransportationsCorrection = ({ open, onClose }) => {
  const [openTransportationCorrection, setOpenTransportationCorrection] =
    useState(false);
  const [error, setError] = useState(false);
  const { selectedUser, monthIndex } = useContext(GlobalContext);

  const location = useLocation();

  // const username = location.state.name;
  const username = selectedUser;

  // const monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
  const monthNumber = monthIndex + 1;
  const yearNumber = parseInt(localStorage.getItem("currentYear"));
  const today = new Date();

  const transportationsCorrection = useRef(null);

  const editTransportations = async () => {
    const transportationCorrectionInput =
      transportationsCorrection.current.querySelector("input").value;

    if (transportationCorrectionInput !== "") {
      setError(false);

      const status = await axios
        .post(process.env.REACT_APP_API_URL + "/approval-hours", {
          username: username,
          month: monthNumber,
          year: yearNumber,
        })
        .then(async (doc) => {
          if (doc && doc.data !== null) {
            console.log(doc);
            const response = await axios
              .patch(`${process.env.REACT_APP_API_URL}/approval-hours/edit`, {
                // username: location.state.name,
                username: selectedUser,
                month: monthNumber,
                year: yearNumber,
                transportations: transportationCorrectionInput,
                date: today,
              })
              .then(async (doc) => {
                setOpenTransportationCorrection(false);
                handleClose();
              });
          }
        });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (open) {
      setOpenTransportationCorrection(true);
    } else {
      setOpenTransportationCorrection(false);
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    setOpenTransportationCorrection(false);
  };

  return (
    <BootstrapDialog
      open={openTransportationCorrection}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      className="more-options-dialog md:w-1/2 m-auto"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Korekcija prevozov
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <div>
          <TextField
            error={error}
            required
            id="outlined-required"
            type="number"
            label="Vpiši novo število prevozov"
            variant="filled"
            className="w-full"
            ref={transportationsCorrection}
          />
          <div className="mt-2">
            <Button
              className="btn w-full primary-btn"
              onClick={editTransportations}
            >
              Potrdi
            </Button>
          </div>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default TransportationsCorrection;
