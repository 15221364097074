import React from "react";
import VacationsCalendar from "../Calendar/VacationCalendar";
import { Container, TextField, Button, Box } from "@mui/material";
import { useState, useEffect } from "react";
import Header from "../Header/Header";
import LoadingSpinner from "../Spinner/LoadingSpinner";

const Vacations = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  return (
    <div className="h-screen">
      <Header />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="bg-primary pt-67 h-full">
          <Container maxWidth="xl" className="text-right pt-[5px] pb-[60px]">
            <VacationsCalendar />
          </Container>
        </div>
      )}
    </div>
  );
};

export default Vacations;
