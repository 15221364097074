import React from "react";
import "./Spinner.css";
import { RotatingLines } from "react-loader-spinner";

export default function Spinner() {
  return (
    <div className="spinner-center">
      <RotatingLines
        visible={true}
        height="36"
        width="36"
        color="red"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}
