import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./store/auth-context";
import { Provider } from "react-redux";
import store from './store/redux/store';
import ContextWrapper from './context/ContextWrapper';
import Layout from './components/Layout';
import { fetchEntries } from './store/redux/slices/entriesSlice';
import { fetchUsers } from './store/redux/slices/usersSlice';
import { setAuthStatus } from './store/redux/slices/msalInstanceSlice';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import Auth from "./Auth";
import { MsalProvider } from '@azure/msal-react';


const userId = localStorage.getItem("userId");
const userRole = localStorage.getItem("role");

const msalConfig = {
  auth: {
    clientId: "de253414-5d86-4e77-a730-1080ac11be82",
    authority:
      "https://login.microsoftonline.com/16eadd03-06c2-48df-b947-30fa0209fb7a",
    redirectUri: "/",
    navigateToLoginRequestUrl: true
  
  }
};

const msalInstance = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    {/* <AuthContextProvider> */}
      <BrowserRouter>
        <Layout>
          <ContextWrapper>
            <Auth msal={msalInstance}>
              <App />
            </Auth>
          </ContextWrapper>
        </Layout>
      </BrowserRouter>
    {/* </AuthContextProvider> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
