import React from "react";
import "./Spinner.css";
import { MutatingDots } from "react-loader-spinner";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <MutatingDots
        height="100"
        width="100"
        color="#333F48"
        secondaryColor="#DB0B33"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}
