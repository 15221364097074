import React from "react";
import { useLocation } from "react-router-dom";
import SmallCalendar from "./SmallCalendar";
import StatusSidebar from "./StatusSidebar";

export default function Sidebar() {
  const location = useLocation();
  return (
    <aside className="aside md:w-64 mb-3 md:mr-3">
      {!location.pathname.includes("/user") && <StatusSidebar />}

      {!location.pathname.includes("/user") && <SmallCalendar />}
    </aside>
  );
}
